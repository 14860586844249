<template>
  <div>
    <vx-card class="bg-primary">

     <div class=" grid md:grid-cols-4 gap-4">

      <div class="basis-1/5 bg-primaryLight p-4 rounded text-center">
        <router-link to="/ticket" class="text-white text-2xl font-bold">تیکت ها</router-link>
        <form class="text-left mt-3">
          <label for="ticketID block ">شماره تیکت</label>
          <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="ticketId" type="text" id="ticketId">
          <input class="bg-secondary p-4 w-full rounded mt-2 text-base text-white" type="submit" @click.stop.prevent="goToTicket(ticketId)" value="برو به این تیکت">
        </form>
      </div>
      <div class="basis-1/5 bg-primaryLight p-4 rounded text-center">
        <router-link to="/userlist" class="text-white text-2xl font-bold">کاربران</router-link>
        <form class="text-left mt-3">
          <label for="userId">آیدی کاربر</label>
          <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="userId" type="text" id="userId">
          <input class="bg-secondary p-4 w-full rounded mt-2 text-base text-white" type="submit" @click.stop.prevent="goToUser(userId)" value="برو به صفحه این کاربر">
        </form>
      </div>
      <div class="basis-1/5 bg-primaryLight p-4 rounded text-center">
        <span to="/ticket" class="text-white text-2xl font-bold">اطلاعات ماشین</span>
        <form class="text-left mt-3">
          <label for="machineNumber">شماره ماشین</label>
          <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="machineNumber" type="text" id="machineNumber">
          <input class="bg-secondary p-4 w-full rounded mt-2 text-base text-white" type="submit"
           @click.stop.prevent="machineInfoPopup = true, vmNumber = machineNumber, machineDetail(machineNumber), getTasks(machineNumber)" value=" اطلاعات ماشین">
        </form>
      </div>

      <div class="basis-1/5 bg-primaryLight p-4 rounded text-center">
        <span to="/ticket" class="text-white text-2xl font-bold">ورکر های نود</span>
        <form class="text-left mt-3">
          <label for="machineNumber">شماره نود</label>
          <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="nodeNumber" type="text" id="machineNumber">
          <div class="text-center">
            <input class="border-0 bg-danger p-4 rounded-lg mt-2 text-base text-white" type="submit"
            @click.stop.prevent="stopWorker(nodeNumber)" value="متوقف کردن ورکر">
            <input class="border-0 bg-success p-4 rounded-lg ml-2 mt-2 text-base text-white" type="submit"
            @click.stop.prevent="startWorker(nodeNumber)" value="استارت ورکر">
          </div>
        </form>
      </div>
      <!-- <div class="basis-1/4 bg-primaryLight p-4 rounded text-center">
        <span to="/ticket" class="text-white text-2xl font-bold">تنظیم وضعیت آیپی</span>
        <form class="text-left mt-3">
          <label for="IPAddress">آیپی</label>
          <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="IPAddress" type="text" id="IPAddress">
          <input class="bg-secondary p-4 w-full rounded mt-2 text-base text-white" type="submit"
           @click.stop.prevent="IPStatusPopup = true, SetIPStatus(IPAddress)" value="تنظیم">
        </form>
      </div> -->

     </div>
     <div class="mt-8 grid md:grid-cols-1 gap-4">
      <div class="w-full bg-primaryLight p-4 rounded text-center">
        <vs-table :data="ipsDetail">
          <template slot="header">
            <h3 class="my-4">
              وضعیت آیپی ها
            </h3>
            <vs-button color="dark" type="relief" icon="refresh" @click="getIPsDetails()"></vs-button>

          </template>
          <template slot="thead">
            <vs-th>
              عنوان
            </vs-th>
            <vs-th>
              تعداد نود
            </vs-th>
            <vs-th>
              کل آیپی ها
            </vs-th>
            <vs-th>
              کل آیپی های فعال
            </vs-th>
            <vs-th>
              کل آیپی های غیرفعال
            </vs-th>
            <vs-th>
              کل آیپی های غیرفعال استفاده شده
            </vs-th>
            <vs-th>
              استفاده شده
            </vs-th>
            <vs-th>
              استفاده نشده
            </vs-th>
            <vs-th>
              فیلتر شده
            </vs-th>
            <!-- <vs-th>
              در مدار
            </vs-th> -->
          </template>

          <template slot-scope="{data}">
            <vs-tr :state=" ipsDetail[indextr].total_ip_active_unused > 0?'success':ipsDetail[indextr].total_ip_active_unused == 0?'danger':null" :key="indextr" v-for="(tr, indextr) in ipsDetail" >
              <vs-td :data="ipsDetail[indextr].title">
                {{ipsDetail[indextr].title}}
              </vs-td>

              <vs-td :data="ipsDetail[indextr].total_node">
                {{ipsDetail[indextr].total_node}}
              </vs-td>

              <vs-td :data="ipsDetail[indextr].total_ip">
                {{ipsDetail[indextr].total_ip}}
              </vs-td>
              <vs-td :data="ipsDetail[indextr].total_ip_active">
                {{ipsDetail[indextr].total_ip_active}}
              </vs-td>
              <vs-td :data="ipsDetail[indextr].total_ip_disabled">
                {{ipsDetail[indextr].total_ip_disabled}}
              </vs-td>
              <vs-td :data="ipsDetail[indextr].total_ip_disabled_used">
                {{ipsDetail[indextr].total_ip_disabled_used}}
              </vs-td>
              <vs-td :data="ipsDetail[indextr].total_ip_active_used">
                {{ipsDetail[indextr].total_ip_active_used}}
              </vs-td>
              <vs-td :data="ipsDetail[indextr].total_ip_active_unused">
                {{ipsDetail[indextr].total_ip_active_unused}}
              </vs-td>
              <vs-td class="text-warning" :data="ipsDetail[indextr].total_ip_is_filter">
                {{ipsDetail[indextr].total_ip_is_filter}}
              </vs-td>
              <!-- <vs-td :data="ipsDetail[indextr].total_ip_is_filter">
                <span v-if="ipsDetail[indextr].total_ip_active_unused-ipsDetail[indextr].total_ip_is_filter >= 0">{{ ipsDetail[indextr].total_ip_active_unused-ipsDetail[indextr].total_ip_is_filter  }} </span>
                <span v-else>0</span>
              </vs-td> -->
            </vs-tr>
          </template>
        </vs-table>
      </div>


    </div>
    <div v-if="activeUserInfo.email === 'hmahnazi@gmail.com' || activeUserInfo.email === 'sajadrasti@haiocloud.com' || activeUserInfo.email ==='m.hooshmand@haiocloud.com' || activeUserInfo.email ==='alizowghi@gmail.com' || activeUserInfo.email ==='fatemeazdiiii2130@gmail.com' " class="w-full bg-primaryLight p-4 rounded text-center">
        <h2>گزارش های مدیریت</h2>

          <div class="mt-8 flex gap-2">
            <vs-button type="gradient" color="secondary" @click="getUsersAndIPS()">دریافت اکسل مشتری ها و آیپی ها</vs-button>
            <vs-button type="gradient" color="secondary" @click="getAllTrans = !getAllTrans">دریافت کل پرداخت ها</vs-button>
            <vs-button type="gradient" color="secondary" @click="getAllUsers()">دریافت کل کاربران</vs-button>

          </div>
          <div v-if="getAllTrans" class="mt-8 flex gap-2">
            <vs-input class="inputx" placeholder="کاربر" type="text" v-model="user" />
            <vs-input class="inputx" placeholder="تاریخ شروع" type="text" v-model="start" />
            <vs-input class="inputx" placeholder="تاریخ پایان" type="text" v-model="end" />
            <vs-button type="gradient" color="secondary" @click="getAllTransactions(user, start, end)">ارسال</vs-button>
          </div>
          <div class="mt-8 flex gap-2">
            <vs-button type="gradient" color="secondary" @click="getDailyPayment(), dailyPayment = !dailyPayment"> شارژ روزانه در درگاه حقیقی</vs-button>

          </div>
          <div v-if="dailyPayment">
              <span class="text-left block mt-8 mb-4">
                مقدار فعلی روی
                {{ dailyPaymentAmont | money }} تومان
                تنظیم شده است
              </span>
              <vs-input type="number" v-model="dailyPaymentAmont" />
              <vs-button type="gradient" color="secondary" @click="setDailyPayment()">تنظیم مقدار جدید</vs-button>
            </div>
          <a  class="mt-8 bg-success border-0 py-3 px-8 rounded cursor-pointer block" :href="downloadLink" >{{ downloadText }}</a>
          <vs-divider />
            <div class="flex mt-4 gap-2">
              <vs-input type="text" v-model="trafficFromDate" />
              <vs-input type="text" v-model="trafficToDate" />
              <vs-button type="gradient" color="secondary" @click="getAllTrafficStats()">دریافت اطلاعات</vs-button>
            </div>
            <div class="mt-4">
              <div class="flex mt-2 gap-2">
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">دریافتی کل</span>
                  <span class="text-2xl text-center">{{allTotalReceived}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">ارسال کل</span>
                  <span class="text-2xl text-center">{{allTotalSent}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">هزینه کل</span>
                  <span class="text-2xl text-center">{{allTrafficCost}}</span>
                </div>

                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">دریافتی 1:10</span>
                  <span class="text-2xl text-center">{{normalTotalReceived}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">ارسال 1:10</span>
                  <span class="text-2xl text-center">{{normalTotalsent}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">هزینه 1:10</span>
                  <span class="text-2xl text-center">{{normalTotalCost}}</span>
                </div>
              </div>
              <div class="flex mt-2 gap-2">
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">دریافتی 1:1</span>
                  <span class="text-2xl text-center">{{notNormalTotalReceived}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">ارسال 1:1</span>
                  <span class="text-2xl text-center">{{notNormalTotalsent}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">هزینه 1:1</span>
                  <span class="text-2xl text-center">{{notNormalTotalCost}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">دریافتی فیک</span>
                  <span class="text-2xl text-center">{{badTotalReceived}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">ارسال فیک</span>
                  <span class="text-2xl text-center">{{badTotalSent}}</span>
                </div>
                <div class="bg-primary py-2 px-5 rounded">
                  <span class="text-sm block text-left mb-2">هزینه فیک</span>
                  <span class="text-2xl text-center">{{badTrafficCost}}</span>
                </div>
              </div>
            </div>
      </div>
    </vx-card>
    <vs-popup
      :fullscreen="true"
      title="اطلاعات ماشین"
      :active.sync="machineInfoPopup"
    >

    <machineInfoCard
    :mydata="{
        machine: this.machineInfo,
        machineNumber: this.machineNumber,
        machinInfoMessage: this.machinInfoMessage,
        taskslist: this.taskslist,
        taskslistParams: this.taskslistParams,
        currenttasksPage: this.currenttasksPage
    }"

    ></machineInfoCard>
    </vs-popup>
    <!-- Set IP Status popup -->
    <!-- <vs-popup
      :fullscreen="false"
      title="وضعیت آیپی"
      :active.sync="IPStatusPopup"
    >
    <SetIPStatus
    :mydata="{
        ip_address: this.IPAddress
    }"

    ></SetIPStatus>
    </vs-popup> -->
  </div>
</template>
<script>

import machineInfoCard from "./components/machinInfoCard.vue"
import SetIPStatus from "./components/SetIPStatus.vue"
import moment from 'moment-jalaali'

export default {
  components: {
    SetIPStatus,
    machineInfoCard
  },
  data() {
    return {
      ticketId:'',
      userId:'',
      machineNumber: '',
      machineInfoPopup: false,
      machineInfo:[],
      machinInfoMessage:'',
      currenttasksPage:1,
      taskslistParams: [],
      taskslist:[],
      alltasksPage: 1,
      allTotal: 0,
      alltaskslistParams: [],
      alltaskslist:[],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'method_name', label: 'Method Name' },
        { key: 'status', label: 'Status' },
        { key: 'details', label: 'Details' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions', label: 'Actions' }
      ],
      IPAddress:'',
      ipsDetail:[],
      IPStatusPopup: false,
      nodeNumber: '',
      downloadLink:'#',
      downloadText:'چیزی برای دانلود وجود ندارد',
      getAllTrans: false,
      user: '',
      start: '',
      end: '',
      dailyPayment: false,
      dailyPaymentAmont: 0,
      // Traffic information
      trafficFromDate: moment().format('jYYYY-jM-jD'),
      trafficToDate: moment().format('jYYYY-jM-jD'),
      allTotalReceived:'',
      allTotalSent: '',
      allTrafficCost: '',
      normalTotalReceived: '',
      normalTotalsent:'',
      normalTotalCost:'',
      notNormalTotalReceived: '',
      notNormalTotalsent:'',
      notNormalTotalCost:'',
      badTotalReceived:'',
      badTotalSent: '',
      badTrafficCost: '',

    };
  },
  watch: {
    alltasksPage () {
      this.getAllTasks();
    },

  },
  methods: {
    goToTicket(id) {
      this.$router.push(`/ticket/${id}`);
    },
    goToUser(id) {
      this.$router.push(`/user?id=${id}`);
    },
    getTasks(vm_id) {
      const mahcine_vm_n = vm_id
      this.$http.get(`/admin/cloud/tasks?filter=&page=&user_id=&vm_id=${mahcine_vm_n}&node_id=&status=&page=${this.currenttasksPage}`)
        .then(response => {
          this.taskslistParams = response.data.params
          this.taskslist = response.data.params.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    getAllTasks() {
      this.$http.get(`/admin/cloud/tasks?filter=&page=${this.alltasksPage}&user_id=&vm_id=&node_id=&status=`)
        .then(response => {
          this.alltaskslistParams = response.data.params
          this.allTotal = response.data.params.last_page
          this.alltaskslist = response.data.params.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    machineDetail(id) {
      this.$http
          .get(`/admin/cloud/vm/${id.trim()}`)
          .then((res) => {
            this.machineInfo =  res.data.params;
            return this.machinInfoMessage = ''

          })
          .catch((err) => {
            console.log(err);
            if (err) {
              return this.machinInfoMessage = '404'
            }

        });

    },
    reRunTask(id) {
      this.$http
        .post("/admin/cloud/tasks/rerun",{task_id: id})
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    cancelTask(id) {
      this.$http
        .post("/admin/cloud/tasks/cancel",{task_id: id})
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    getIPsDetails(){
      this.$http.get('/admin/cloud/datacenter/info')
      .then((res) => {
        this.ipsDetail = res.data.params.data;
      });
    },
    startWorker(node_id) {
      this.$http.get(`/admin/cloud/node/worker/start/${node_id}`)
      .then((res) => {
        this.$vs.notify({ text: res.data.message });
      })
      .catch((err) => {
        this.$vs.notify({ text: err.response.data.message });
      });
  },
    stopWorker(node_id) {
      this.$http.get(`/admin/cloud/node/worker/stop/${node_id}`)
      .then((res) => {
        this.$vs.notify({ text: res.data.message });
      })
      .catch((err) => {
        this.$vs.notify({ text: err.response.data.message });
      });
  },
  getAllUsers() {
    this.downloadText= 'درحال انجام عملیات...'
    this.$http.get('/admin/excel/alluser/export')
    .then((res) => {
      this.downloadLink = res.data.replace('http://', 'https://');
      this.downloadText= 'فایل شما آماده دانلود است'
    })
    .catch((err) => {
        this.$vs.notify({ text: err.response.data.message });
      });
  },
  getUsersAndIPS() {
    this.downloadText= 'درحال انجام عملیات...'
    this.$http.get('/admin/excel/ips/export')
    .then((res) => {
      this.downloadLink = res.data.replace('http://', 'https://');
      this.downloadText= 'فایل شما آماده دانلود است'
    })
    .catch((err) => {
        this.$vs.notify({ text: err.response.data.message });
      });
  },
  getAllTransactions(user, start, end) {
    this.getAllTrans = true;
    this.downloadText= 'درحال انجام عملیات...'
    this.$http.post('/admin/excel/alldeposit/export',{user_id: user, from_date: start, to_date: end})
    .then((res) => {
      this.downloadText= 'فایل شما آماده دانلود است'
      this.downloadLink = res.data.replace('http://', 'https://');
    })
    .catch((err) => {
        this.$vs.notify({ text: err.response.data.message });
      });
  },
  getDailyPayment() {
    this.$http.get('/admin/setting/list').then((res) => {
      this.dailyPaymentAmont = res.data.params.data[1].setting_value
    }).catch((err) => {
      console.log(err.response.data.message);
    });
  },
  setDailyPayment() {
    this.$http.post('/admin/setting/change/2', { setting_value: this.dailyPaymentAmont, setting_state: 1})
    .then((res) => {
      this.$vs.notify({ text: res.response.data.message });
    }).catch((err) => {
      this.$vs.notify({ text: err.response.data.message });
    })
  },
  getAllTrafficStats() {
    this.$http.post('/admin/cloud/traffic/logs', { from_date: this.trafficFromDate, to_date: this.trafficToDate})
    .then((res) => {
      this.allTotalReceived = res.data.params.All.total_received;
      this.allTotalSent = res.data.params.All.total_sent
      this.allTrafficCost = res.data.params.All.traffic_cost
      this.normalTotalReceived = res.data.params.Normal.total_received
      this.normalTotalsent = res.data.params.Normal.total_sent
      this.normalTotalCost = res.data.params.Normal.traffic_cost
      this.notNormalTotalReceived = res.data.params.NotNormal.total_received
      this.notNormalTotalsent = res.data.params.NotNormal.total_sent
      this.notNormalTotalCost = res.data.params.NotNormal.traffic_cost
      this.badTotalReceived = res.data.params.BadTraffic.total_received
      this.badTotalSent = res.data.params.BadTraffic.total_sent
      this.badTrafficCost = res.data.params.BadTraffic.traffic_cost
    });
  }
},
computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
created() {
    // this.getAllTasks()
    this.getIPsDetails()
  },
}

</script>
<style scoped>
.hidescrollbar::-webkit-scrollbar {
  display: none;
}
th {
  text-align: right !important;
}
</style>
