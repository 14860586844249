<template>
    <div>
        <h4 class="text-center">شمار درحال تنظیم وضعیت آیپی {{ ip_address }} هستید. </h4>
        <div class="flex gap-2 flex-wrap mt-4 justify-center">

            <vs-select placeholder="Select" label="همراه اول" v-model="ping_mci">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            <vs-select placeholder="Select" label="ایرانسل" v-model="ping_mtn">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            <vs-select placeholder="Select" label="رایتل" v-model="ping_rightel">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            <vs-select placeholder="Select" label="مخابرات" v-model="ping_tci">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            <vs-select placeholder="Select" label="زیتل" v-model="ping_zitel">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            <vs-select placeholder="Select" label="شاتل" v-model="ping_shatel">
                <vs-select-item value="0" text="سالم"  />
                <vs-select-item value="1" text="فیلتر"  />
                <vs-select-item value="2" text="نامشخص"  />
            </vs-select>
            
        </div>

        <div class="bg-primary rounded p-3 my-4">
            <span class="text-white">
                خلاصه وضعیتی که قصد تعریف آن را دارید:
            </span>
            <vs-divider />
            <div class="flex gap flex-wrap gap-2">
            
                <span class="text-white bg-primaryLight rounded p-2">
                    همراه اول:
                    <span v-if="ping_mci == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_mci == 0 && ping_mci != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_mci == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_mci == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_mci }})
                </span>
                
                <span class="text-white bg-primaryLight rounded p-2">
                    ایرانسل:
                    <span v-if="ping_mtn == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_mtn == 0 && ping_mtn != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_mtn == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_mtn == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_mtn }})
                </span>

                <span class="text-white bg-primaryLight rounded p-2">
                    رایتل:
                    <span v-if="ping_rightel == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_rightel == 0 && ping_rightel != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_rightel == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_rightel == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_rightel }})
                </span>

                <span class="text-white bg-primaryLight rounded p-2">
                    مخابرات:
                    <span v-if="ping_tci == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_tci == 0 && ping_tci != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_tci == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_tci == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_tci }})
                </span>

                <span class="text-white bg-primaryLight rounded p-2">
                    زیتل:
                    <span v-if="ping_zitel == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_zitel == 0 && ping_zitel != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_zitel == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_zitel == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_zitel }})
                </span>

                <span class="text-white bg-primaryLight rounded p-2">
                    شاتل:
                    <span v-if="ping_shatel == ''" class="text-grey">
                        تعریف نشده 
                    </span>
                    <span v-if="ping_shatel == 0 && ping_shatel != ''" class="text-success">
                        سالم
                    </span>
                    <span v-if="ping_shatel == 1" class="text-danger">
                        فیلتر
                    </span>
                    <span v-if="ping_shatel == 2" class="text-grey">
                        نامشخص
                    </span>
                    ({{ ping_shatel }})
                </span>

            </div>
            <vs-button class="bg-secondary mt-4 w-full">تایید میکنم</vs-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mydata: { required: true },
      
    },
    data() {
    return {
        ip_address: this.mydata.ip_address,
        ping_mci:'',
        ping_mtn:'',
        ping_rightel:'',
        ping_tci:'',
        ping_zitel:'',
        ping_shatel:'',
    }
}
}
</script>